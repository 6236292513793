import { useState, useContext, createContext, useEffect } from 'react';

interface IAlertContextType {
  Primary: string
  Secondary: string
  Success: string
  Danger: string
  Warning: string
  Info: string
  Light: string
  Dark: string
}

interface IAlertLayoutContext {
  AlertContextType: IAlertContextType,
  alertNotices: typeof AlertNotice[],
  pushNotice: (props: typeof AlertNotice) => void,
  pushMessageWithContext: (alertContext: string, message: string) => void,
  pushErrorMessage: (message: string) => void,
}

const AlertContextType: IAlertContextType = {
  Primary: 'alert-primary',
  Secondary: 'alert-secondary',
  Success: 'alert-success',
  Danger: 'alert-danger',
  Warning: 'alert-warning',
  Info: 'alert-info',
  Light: 'alert-light',
  Dark: 'alert-dark',
}

const AlertNoticeAnchor = (props: any) => {
  const { alertNotices } = useAlertContext();

  const displayNotices = () => {
    return alertNotices;
  }

  return (
    <div className='alert-notice-container'>
      {displayNotices()}
    </div>
  )
}

function guidGenerator() {
  var S4 = function() {
     return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

const AlertNotice: any = (props: typeof AlertNotice): any => {
  const { children, context } = props;

  return (
    <div key={`alert-${guidGenerator()}`} className={`alert ${context}`} role='alert'>
      {children}
    </div>  
  )
}

const AlertLayoutContext = createContext<IAlertLayoutContext | undefined>(undefined);

let noticeCleanupInterval: any;

const AlertLayoutProvider = (props: any) => {

  const [alertNotices] = useState<typeof AlertNotice[]>([]);
  const [countOfNotices, setCountOfNotices] = useState<number>(0);
  const { children } = props;

  useEffect(() => {
    initCleanupInterval();
  }, [countOfNotices])
  
  const removeNotice = () => {
    if (countOfNotices > 0) {
      alertNotices.shift();
      setCountOfNotices(alertNotices.length);
    } else {
      cancelCleanupInterval();
    }
  }

  const cancelCleanupInterval = () => {
    if (noticeCleanupInterval !== undefined) {
      clearInterval(noticeCleanupInterval);
      noticeCleanupInterval = undefined;
    }
  }

  const initCleanupInterval = () => {
    if (alertNotices.length) {
      if (noticeCleanupInterval === undefined) {
        noticeCleanupInterval = setInterval(removeNotice, 8500);
      }
    } else {
      cancelCleanupInterval();
    }
  }

  const pushNotice = (notice: typeof AlertNotice) => {
    cancelCleanupInterval();

    alertNotices.push(notice);
    setCountOfNotices(alertNotices.length);
  }

  const pushMessageWithContext = (alertContext: string, message: string) => {
    pushNotice(
      <AlertNotice key={`notice-${guidGenerator()}`} context={alertContext}>
        {message}
      </AlertNotice>
    )
  }

  const pushErrorMessage = (message: string) => {
    pushMessageWithContext(AlertContextType?.Danger, message)
  }

  return (
    <AlertLayoutContext.Provider value={{
      AlertContextType: AlertContextType,
      alertNotices,
      pushNotice,
      pushMessageWithContext,
      pushErrorMessage,
    }}>
      {children}
    </AlertLayoutContext.Provider>
  )
}

const useAlertContext = (): any => {
  const context = useContext(AlertLayoutContext);
  if (context === undefined) {
    throw new Error('useAlertContext must be used within AlertLayoutProvider');
  }
  return context;
}

export {
  AlertLayoutContext,
  AlertLayoutProvider,
  AlertNoticeAnchor,
  AlertNotice,
  useAlertContext,
}