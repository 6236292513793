import { Link, useLocation } from 'react-router-dom';
import 'assets/css/navbar-menu.scss';
import logo from 'assets/images/gonelocal_logo_white.png';

const PageLayoutHeader = () => {
  const location = useLocation();

  return (
    <nav className='navbar sticky-top navbar-expand-lg navbar-dark bg-dark'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to='/'><img src={logo} className='navbar-logo' alt='GoneLocal'/></Link>
        <button className='navbar-toggler' type='button' 
          data-bs-toggle='collapse' 
          data-bs-target='#navbarSupportedContent' 
          aria-controls='navbarSupportedContent' 
          aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
          <li className='nav-item'>
              <Link className={`nav-link${location.pathname === '/merchants' ? ' active' : ''}`} area-current='page' to='/merchants'>Companies</Link>
            </li>
            <li className="nav-item dropdown">
              <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Categories
              </div>
              <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
              <li>
                  <Link className={`nav-link${location.pathname === '/categories/events' ? ' active' : ''}`} area-current='page' to='/categories/events'>Events</Link>
                </li>
                <li>
                  <Link className={`nav-link${location.pathname === '/categories/organizations' ? ' active' : ''}`} area-current='page' to='/categories/organizations'>Organizations</Link>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' area-current='page' to='/logout'>Logout</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default PageLayoutHeader;