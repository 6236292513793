import React from 'react';

const PageLayoutFooter = () => {
  return (
    <nav className='navbar fixed-bottom navbar-light bg-light'>
      <div className='container-fluid'>
        <span className='copyright'>&copy; GoneLocal {(new Date()).getFullYear()}</span>
      </div>
    </nav>
  )
}

export default PageLayoutFooter;