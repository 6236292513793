import React from 'react'
import 'assets/css/loaders.scss'

interface IThreeDotLoader {
  show: boolean
}

const ThreeDotLoader = (props: IThreeDotLoader) => {
  if (props.show) {
    return (
      <div className="floating-loader">
        <div className="floating-loader-center">
          <div className="dot-opacity-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    )  
  } else {
    return (<></>)
  }
}

export default ThreeDotLoader
