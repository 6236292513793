import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAlertContext, AlertNotice } from 'context/AlertLayoutContext';
import { LIST_CATEGORIES_EVENTS, LIST_CATEGORIES_ORGANIZATIONS } from 'data/queries';
import { 
  ARCHIVE_CATEGORY, 
  CREATE_CATEGORY_FOR_EVENT, 
  CREATE_CATEGORY_FOR_ORGANIZATION,
  MODIFY_CATEGORY_FOR_EVENT,
  MODIFY_CATEGORY_FOR_ORGANIZATION, 
} from 'data/mutations';
import {
  IPagination,
  IDataPagination,
  ICategoryForEvent,
  ICategoryForOrganization,
} from 'data/types';

export default function useCategoryHook() {
  const navigate = useNavigate();
  const { pushErrorMessage, pushNotice, AlertContextType } = useAlertContext();
  const [eventCategories, setEventCategories] = useState<ICategoryForEvent[]>();
  const [organizationCategories, setOrganizationCategories] = useState<ICategoryForOrganization[]>();
  const [eventCategorySelectOptions, setEventCategorySelectOptions] = useState<any[]>();
  const [organizationCategorySelectOptions, setOrganizationCategorySelectOptions] = useState<any[]>();
  const [selectedEventCategory, setSelectedEventCategory] = useState<ICategoryForEvent>();
  const [selectedOrganizationCategory, setSelectedOrganizationCategory] = useState<ICategoryForOrganization>();
  const [eventCategoryPagination, setEventCategoryPagination] = useState<IPagination>();
  const [organizationCategoryPagination, setOrganizationCategoryPagination] = useState<IPagination>();
  const [listEventCategories, listEventCategoryContext] = useLazyQuery(LIST_CATEGORIES_EVENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const categoryData: IDataPagination = data.categories;
      setEventCategories(categoryData?.items);
      setEventCategoryPagination(categoryData?.pagination);

      const categories: any[] = [];
      categoryData?.items?.forEach((category: ICategoryForEvent) => {
        categories.push(
          <option key={category.categoryForEventUuid} value={category.categoryForEventUuid}>
            {category.name}
          </option>
        )
      });

      setEventCategorySelectOptions(categories);
    },
    onError: error => {
      pushErrorMessage(`Unable to list event categories: ${error.message}`)
    }
  })

  const [listOrganizationCategories, listOrganizationCategoryContext] = useLazyQuery(LIST_CATEGORIES_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const categoryData: IDataPagination = data.categories;
      setOrganizationCategories(categoryData?.items);
      setOrganizationCategoryPagination(categoryData?.pagination);
      
      const categories: any[] = [];
      categoryData?.items?.forEach((category: ICategoryForOrganization) => {
        categories.push(
          <option key={category.categoryForOrganizationUuid} value={category.categoryForOrganizationUuid}>
            {category.name}
          </option>
        )
      });
      
      setOrganizationCategorySelectOptions(categories);
    },
    onError: error => {
      pushErrorMessage(`Unable to list organization categories: ${error.message}`)
    }
  })

  const [createEventCategory, createEventCategoryContext] = useMutation(CREATE_CATEGORY_FOR_EVENT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_CATEGORIES_EVENTS],
    onCompleted: data => {
      // const categoryData: any = data.createEventCategory;
      pushNotice(
        <AlertNotice key='event-category-archived' context={AlertContextType?.Success}>
          Category created successfully.
        </AlertNotice>
      )
      createEventCategoryContext.reset();
      navigate('/categories/events');
    },
    onError: error => {
      pushErrorMessage(`Unable to create event category: ${error.message}`)
    }
  })

  const [createOrganizationCategory, createOrganizationCategoryContext] = useMutation(CREATE_CATEGORY_FOR_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_CATEGORIES_ORGANIZATIONS],
    onCompleted: data => {
      // const categoryData: any = data.createOrganizationCategory;
      pushNotice(
        <AlertNotice key='organization-category-created' context={AlertContextType?.Success}>
          Category created successfully.
        </AlertNotice>
      )
      createOrganizationCategoryContext.reset();
      navigate('/categories/organizations');
    },
    onError: error => {
      pushErrorMessage(`Unable to create organization category: ${error.message}`)
    }
  })

  const [updateEventCategory, updateEventCategoryContext] = useMutation(MODIFY_CATEGORY_FOR_EVENT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_CATEGORIES_EVENTS],
    onCompleted: data => {
      // const categoryData: any = data.updateEventCategory;
      pushNotice(
        <AlertNotice key='event-category-modified' context={AlertContextType?.Success}>
          Category modified successfully.
        </AlertNotice>
      )
      updateEventCategoryContext.reset();
      navigate('/categories/events');
    },
    onError: error => {
      pushErrorMessage(`Unable to modify event category: ${error.message}`)
    }
  })

  const [updateOrganizationCategory, updateOrganizationCategoryContext] = useMutation(MODIFY_CATEGORY_FOR_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_CATEGORIES_ORGANIZATIONS],
    onCompleted: data => {
      // const categoryData: any = data.updateOrganizationCategory;
      pushNotice(
        <AlertNotice key='organization-category-modified' context={AlertContextType?.Success}>
          Category modified successfully.
        </AlertNotice>
      )
      updateOrganizationCategoryContext.reset();
      navigate('/categories/organizations');
    },
    onError: error => {
      pushErrorMessage(`Unable to modify organization category: ${error.message}`)
    }
  })

  const [archiveCategory, archiveCategoryContext] = useMutation(ARCHIVE_CATEGORY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    refetchQueries: [LIST_CATEGORIES_ORGANIZATIONS,LIST_CATEGORIES_EVENTS],
    onCompleted: data => {
      // const categoryData: any = data.archiveCategory;
      pushNotice(
        <AlertNotice key='category-archived' context={AlertContextType?.Success}>
          Category archived successfully.
        </AlertNotice>
      )
      archiveCategoryContext.reset();
    },
    onError: error => {
      pushErrorMessage(`Unable to archive category: ${error.message}`)
    }
  })

  const listCategories = (whichType: string, page: number, pageSize: number, keywords: string = '') => {
    if (whichType === 'EVENT') {
      listEventCategories({
        variables: {
          whichType,
          page,
          pageSize,
          keywords,
        }
      })
    } else if (whichType === 'ORGANIZATION') {
      listOrganizationCategories({
        variables: {
          whichType,
          page,
          pageSize,
          keywords,
        }
      })
    }
  }

  const archiveCategoryByType = (categoryUuid: string, whichType: string = 'ORGANIZATION') => {
    archiveCategory({
      variables: {
        categoryUuid,
        whichType,
      }
    })
  }

  const modifyCategoryByType = (category: ICategoryForEvent | ICategoryForOrganization, whichType: string = 'ORGANIZATION') => {
    if (whichType === 'EVENT') {
      updateEventCategory({
        variables: {
          category,
        }
      })  
    } else {
      updateOrganizationCategory({
        variables: {
          category,
        }
      })  
    }
  }

  const createCategoryType = (category: ICategoryForEvent | ICategoryForOrganization, whichType: string = 'ORGANZIATION') => {
    if (whichType === 'EVENT') {
      createEventCategory({
        variables: {
          category: Object.assign({}, category, {
            categoryForEventUuid: '',
            viewOrder: -1,
          }),
        }
      })  
    } else {
      createOrganizationCategory({
        variables: {
          category: Object.assign({}, category, {
            categoryForOrganizationUuid: '',
            viewOrder: -1,
          }),
        }
      })  
    }
  }

  const categoryNameFromUuid = (categoryUuid: string, whichType: string = 'ORGANIZATION'): string | undefined => {
    if (whichType === 'ORGANIZATION' && organizationCategories) {
      for(let ndx=0;ndx < organizationCategories?.length;ndx++) {
        if (categoryUuid === organizationCategories[ndx].categoryForOrganizationUuid) {
          return organizationCategories[ndx].name;
        }
      }
    } else if(whichType === 'EVENT' && eventCategories) {
      for(let ndx=0;ndx < eventCategories?.length;ndx++) {
        if (categoryUuid === eventCategories[ndx].categoryForEventUuid) {
          return eventCategories[ndx].name;
        }
      }
    }

    return undefined;
  }

  return {
    selectedEventCategory, setSelectedEventCategory,
    selectedOrganizationCategory, setSelectedOrganizationCategory,
    eventCategorySelectOptions,
    organizationCategorySelectOptions,
    categoryNameFromUuid,
    listCategories,
    createCategoryType,
    modifyCategoryByType,
    archiveCategoryByType,
    eventCategories,
    eventCategoryPagination,
    organizationCategories,
    organizationCategoryPagination,
    listEventCategoryContext,
    listOrganizationCategoryContext,
    createEventCategoryContext,
    updateEventCategoryContext,
    createOrganizationCategoryContext,
    updateOrganizationCategoryContext,
    archiveCategoryContext,
  }
}