import { gql } from '@apollo/client';

const CHANGE_ORGANIZATION_NAME = gql`
mutation changeOrganizationName(
  $organizationUuid: ID!,
  $nameDelta: String!
) {
  changeOrganizationName(
    organizationUuid: $organizationUuid,
    nameDelta: $nameDelta
  ) {
    organizationUuid
    name
    createdAt
    updatedAt
  }
}
`

const RESET_ACCOUNT_CREDENTIALS = gql`
mutation changePassword(
  $identityUuid: ID!,
  $newUsername: String!,
  $newPassword: String!
) {
  changeCredentials(
    identityUuid: $identityUuid,
    newUsername: $newUsername,
    newPassword: $newPassword
  ) {
    success
  }
}
`

const ARCHIVE_ORGANIZATION = gql`
mutation archiveOrganization(
  $organizationUuid: ID!
) {
  archiveOrganization(
    organizationUuid: $organizationUuid
  ) {
    success
  }
}
`

const APPROVE_ORGANIZATION = gql`
mutation approveOrganization(
  $organizationUuid: ID!
) {
  approveOrganization(
    organizationUuid: $organizationUuid
  ) {
    success
  }
}
`

const RESTORE_ORGANIZATION = gql`
mutation restoreOrganization(
  $organizationUuid: ID!
) {
  restoreOrganization(
    organizationUuid: $organizationUuid
  ) {
    success
  }
}
`

const ARCHIVE_CATEGORY = gql`
mutation archiveCategory(
  $categoryUuid: ID!,
  $whichType: String!
) {
  archiveCategory(
    categoryUuid: $categoryUuid,
    whichType: $whichType
  ) {
    success
  }
}
`

const CREATE_CATEGORY_FOR_EVENT = gql`
mutation createEventCategory(
  $category: CategoryForEventInput!
) {
  createEventCategory(
    category: $category
  ) {
    success
  }
}
`

const CREATE_CATEGORY_FOR_ORGANIZATION = gql`
mutation createOrganizationCategory(
  $category: CategoryForOrganizationInput!
) {
  createOrganizationCategory(
    category: $category
  ) {
    success
  }
}
`

const MODIFY_CATEGORY_FOR_EVENT = gql`
mutation updateEventCategory(
  $category: CategoryForEventInput!
) {
  updateEventCategory(
    category: $category
  ) {
    success
  }
}
`

const MODIFY_CATEGORY_FOR_ORGANIZATION = gql`
mutation updateOrganizationCategory(
  $category: CategoryForOrganizationInput!
) {
  updateOrganizationCategory(
    category: $category
  ) {
    success
  }
}
`
export {
  CHANGE_ORGANIZATION_NAME,
  RESET_ACCOUNT_CREDENTIALS,
  APPROVE_ORGANIZATION,
  RESTORE_ORGANIZATION,
  ARCHIVE_ORGANIZATION,
  ARCHIVE_CATEGORY, 
  CREATE_CATEGORY_FOR_EVENT, 
  CREATE_CATEGORY_FOR_ORGANIZATION,
  MODIFY_CATEGORY_FOR_EVENT,
  MODIFY_CATEGORY_FOR_ORGANIZATION, 
}