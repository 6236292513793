// domains.ts file
// const AuthorizeUrl = () => {
//   return "//gonelocal.local:8182";
// }

// const ServiceUrl = () => {
//   return "//gonelocal.local:8183";
// }

const AuthorizeUrl = () => {
  return "https://login.gonelocal.com";
}

const ServiceUrl = () => {
  return "https://services.gonelocal.com";
}

export {
  AuthorizeUrl,
  ServiceUrl,
}