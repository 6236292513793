import React from 'react';
import { createRoot } from 'react-dom/client';
import GraphqlClient from 'data';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { PageLayoutProvider } from 'context/PageLayoutContext';
import { AlertLayoutProvider } from 'context/AlertLayoutContext';
import { PortfolioProvider } from 'context/PortfolioContext';
import 'assets/css/styles.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={GraphqlClient}>
        <AlertLayoutProvider>
          <PortfolioProvider>
            <PageLayoutProvider>
              <App />
            </PageLayoutProvider>
          </PortfolioProvider>
        </AlertLayoutProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
