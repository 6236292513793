import { useState, useContext, createContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import useCategoryHook from 'context/PortfolioContext/categoryHook';
import useMediaHook from 'context/PortfolioContext/mediaHook';
import useGeoHook from 'context/PortfolioContext/geoHook';
import { useAlertContext, AlertNotice } from 'context/AlertLayoutContext';
import { 
  LIST_DEPRECATED_ORGANIZATIONS,
  LIST_UNAPPROVED_ORGANIZATIONS,
  LIST_ORGANIZATIONS, 
  LIST_IMPRESSIONS, 
  LIST_MOST_IMPRESSIONS_BY_ORGANIZATION, 
  LIST_TOP_SEARCH_PHRASES
} from 'data/queries'
import { 
  CHANGE_ORGANIZATION_NAME, 
  ARCHIVE_ORGANIZATION,
  APPROVE_ORGANIZATION,
  RESTORE_ORGANIZATION,
} from 'data/mutations';
import {
  IDataPagination,
  IImpression,
  IImpressionSummary,
  IImpressionBySearchPhrase,
  IImpressionByOrganization,
  IOrganization,
  ISessionScope,
  IPagination,
} from 'data/types';

interface IPortfolioContext {
  sessionContext: ISessionScope | undefined
  setSessionContext: (contextScope: any) => void
  organizations: IOrganization[] | undefined
  organizationPagination: IPagination | undefined
  deprecatedOrganizations: IOrganization[] | undefined
  deprecatedOrganizationPagination: IPagination | undefined
  unapprovedOrganizations: IOrganization[] | undefined
  unapprovedOrganizationPagination: IPagination | undefined
  impressions: IImpressionSummary | undefined
  impressionsByOrganization: IImpressionByOrganization[] | undefined
  impressionsBySearchPhrase: IImpressionBySearchPhrase[] | undefined
  isLoadingOrganization: boolean
  isLoadingUnapprovedOrganizations: boolean,
  isLoadingDeprecatedOrganizations: boolean,
  fetchContext: any
  nameChangeContext: any
  changeName: (organizationUuid: string, name: string) => void,
  archive: (organizationUuid: string) => void,
  approve: (organizationUuid: string) => void,
  restore: (organizationUuid: string) => void,
  isArchivingOrganizaiton: boolean,
  portfolioSearch: (page: number, pageSize: number, keywords: string) => void,
  portfolioPage: (page: number, pageSize: number) => void,
  requestImpressions: (organizationUuid: string, fromTimestamp: string, toTimestamp: string) => void
  requestTopImpressions: (fromTimestamp: string, toTimestamp: string) => void
  requestTopSearchPhrases: (fromTimestamp: string, toTimestamp: string) => void,
  requestDeprecatedOrganizations: (page: number, pageSize: number, keywords: string) => void,
  requestUnapprovedOrganizations: (page: number, pageSize: number, keywords: string) => void,
  hookForCategory: any
  hookForMedia: any
  hookForGeo: any
  blankOrganization: IOrganization
}

const PortfolioContext = createContext<IPortfolioContext | undefined>(undefined);

const PortfolioProvider = (props: any) => {
  const { children } = props;
  const hookForCategory = useCategoryHook();
  const hookForMedia = useMediaHook();
  const hookForGeo = useGeoHook();

  let contextScope: any | undefined;
  const data: string | null = sessionStorage.getItem("context");
  if (data) {
    const scope: any = window.atob(data);
    if (scope) {
      const scopeDetail: any = JSON.parse(scope);
      if (scopeDetail) {
        contextScope = scopeDetail.data;  
      }
    }
  }

  const [sessionContext, setSessionContext] = useState<ISessionScope>(contextScope);
  const { pushNotice, AlertContextType } = useAlertContext();
  const [hasRequestedFullContext, setHasRequestedFullContext] = useState(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [impressions, setImpressions] = useState<IImpressionSummary>();
  const [topOrganizations, setTopOrganizations] = useState<IImpressionByOrganization[]>([]);
  const [topSearchPhrases, setTopSearchPhrases] = useState<IImpressionBySearchPhrase[]>([]);
  const [deprecatedOrganizations, setDeprecatedOrganizations] = useState<IOrganization[]>([]);
  const [unapprovedOrganizations, setUnapprovedOrganizations] = useState<IOrganization[]>([]);
  const [deprecatedOrganizationPagination, setDeprecatedOrganizationPagination] = useState<IPagination>();
  const [unapprovedOrganizationPagination, setUnapprovedOrganizationPagination] = useState<IPagination>();

  const defaultAcceptedCreditCards = [
    {"name":"American Express","accepted":false},
    {"name":"Dinners Card","accepted":false},
    {"name":"Discover","accepted":false},
    {"name":"JP","accepted":false},
    {"name":"Master Card","accepted":false},
    {"name":"Visa","accepted":false}
  ];

  const defaultHoursOfOperation = [
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Sunday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Monday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Tuesday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Wednesday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Thursday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Friday"},
    {"hours": {"isOpen": false, "openAt": "0000", "closeAt": "0000"}, "dayOfWeek": "Saturday"}
  ];

  const blankOrganization: IOrganization = {
    legacyOwnerId: 0,
    organizationUuid: "",
    name: "",
    provinceOrState: 'California',
    country: 'USA',
    keywords: [],
    acceptedCreditCards: defaultAcceptedCreditCards,
    hoursOfOperation: defaultHoursOfOperation,
    hasDelivery: false,
    hasParking: false,
    updatedAt: DateTime.now().toFormat('ccc, LLL dd yyyy'),
  }

  const [organizationsPortfolio, portfolioContext] = useLazyQuery(LIST_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onCompleted: data => {
      const odata: IDataPagination = data?.organizationsPortfolio;
      setOrganizations(odata?.items || [])
      setPagination(odata?.pagination)
    },
    onError: error => {
      console.log('fetchOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization: {error.message}
        </AlertNotice>
      )
    }
  })

  const [fetchDeprecatedOrganizations, deprecatedOrganizationContext] = useLazyQuery(LIST_DEPRECATED_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onCompleted: data => {
      const odata: IDataPagination = data?.deprecatedOrganizations;
      setDeprecatedOrganizations(odata?.items || [])
      setDeprecatedOrganizationPagination(odata?.pagination)
    },
    onError: error => {
      console.log('fetchDeprecatedOrganizations::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organizations(Deprecated): {error.message}
        </AlertNotice>
      )
    }
  })

  const [fetchUnapprovedOrganizations, unapprovedOrganizationContext] = useLazyQuery(LIST_UNAPPROVED_ORGANIZATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onCompleted: data => {
      const odata: IDataPagination = data?.unapprovedOrganizations;
      setUnapprovedOrganizations(odata?.items || [])
      setUnapprovedOrganizationPagination(odata?.pagination)
    },
    onError: error => {
      console.log('fetchUnapprovedOrganizations::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organizations(Unapproved): {error.message}
        </AlertNotice>
      )
    }
  })

  const [mostImpressionsByOrganization, mostImpressionContext] = useLazyQuery(LIST_MOST_IMPRESSIONS_BY_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onCompleted: data => {
      const odata: IDataPagination = data?.mostOrganizationImpressions;
      setTopOrganizations(odata?.items || [])
    },
    onError: error => {
      console.log('mostImpressionsByOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Impressions(By Organization): {error.message}
        </AlertNotice>
      )
    }
  })

  const [mostSearchedTerms, mostSearchedTermsContext] = useLazyQuery(LIST_TOP_SEARCH_PHRASES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onCompleted: data => {
      const odata: IDataPagination = data?.mostSearchedTerms;
      setTopSearchPhrases(odata?.items || [])
    },
    onError: error => {
      console.log('mostSearchedTerms::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Impressions(By Search Phrase): {error.message}
        </AlertNotice>
      )
    }
  })

  const [changeOrganizationName, nameChangeContext] = useMutation(CHANGE_ORGANIZATION_NAME, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    refetchQueries: [LIST_ORGANIZATIONS],
    onCompleted: data => {
      const odata: IOrganization = data?.changeOrganizationName;
      pushNotice(
        <AlertNotice context={AlertContextType?.Success}>
          Organization: Name successfully changed to {odata?.name}
        </AlertNotice>
      )
    },
    onError: error => {
      console.log('fetchOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization: {error.message}
        </AlertNotice>
      )
    }
  })

  const [archiveOrganization, archiveContext] = useMutation(ARCHIVE_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    refetchQueries: [LIST_ORGANIZATIONS],
    onCompleted: data => {
      const odata: any = data?.archiveOrganization;
      pushNotice(
        <AlertNotice context={AlertContextType?.Success}>
          Organization: Successfully archived
        </AlertNotice>
      )
    },
    onError: error => {
      console.log('archiveOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization: {error.message}
        </AlertNotice>
      )
    }
  })

  const [approveOrganization, approveContext] = useMutation(APPROVE_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    refetchQueries: [LIST_ORGANIZATIONS],
    onCompleted: data => {
      const odata: any = data?.approveOrganization;
      pushNotice(
        <AlertNotice context={AlertContextType?.Success}>
          Organization: Successfully approved
        </AlertNotice>
      )
    },
    onError: error => {
      console.log('approveOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization: {error.message}
        </AlertNotice>
      )
    }
  })

  const [restoreOrganization, restoreContext] = useMutation(RESTORE_ORGANIZATION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    refetchQueries: [LIST_ORGANIZATIONS],
    onCompleted: data => {
      const odata: any = data?.restoreOrganization;
      pushNotice(
        <AlertNotice context={AlertContextType?.Success}>
          Organization: Successfully restore
        </AlertNotice>
      )
    },
    onError: error => {
      console.log('restoreOrganization::Error:', error)
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization: {error.message}
        </AlertNotice>
      )
    }
  })

  const [fetchImpressions, fetchContext] = useLazyQuery(LIST_IMPRESSIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const impressionData: IDataPagination = data.impressions;
      const counts: IImpression[] = impressionData?.items
      setImpressions(counts[0]?.tally);
      // let total = 0;
      // let _counts: any = counts?.tally;
      // _counts.forEach((element: any) => {
      //   total += element.impressions;
      // });
      // console.log('total impressions:', total);
    },
    onError: error => {
      console.log('fetchImpressions::Error: ', error);
      pushNotice(
        <AlertNotice context={AlertContextType?.Danger}>
          Organization Impressions: {error.message}
        </AlertNotice>
      )
    }
  });

  useEffect(() => {
    if (!hasRequestedFullContext) {
      setHasRequestedFullContext(true);
      organizationsPortfolio({
        variables: {
          searchParams: {
            page: 0,
            pageSize: 25,
            criteria: []
          }
        }
      })
    }
  }, [
    hasRequestedFullContext,
    setHasRequestedFullContext,
    portfolioContext,
  ])

  const requestImpressions = (organizationUuid: string, fromTimestamp: string, toTimestamp: string) => {
    fetchImpressions({
      variables: {
        organizationUuid,
        fromTimestamp,
        toTimestamp,
      }
    })  
  }

  const portfolioPage = (page: number, pageSize: number) => {
    organizationsPortfolio({
      variables: {
        searchParams: {
          page: page,
          pageSize: pageSize,
          criteria: []
        }
      }
    })
  }

  const portfolioSearch = (page: number, pageSize: number, keywords: string) => {
    organizationsPortfolio({
      variables: {
        searchParams: {
          page: page,
          pageSize: pageSize,
          search: keywords,
          criteria: []
        }
      }
    })
  }

  const changeName = (organizationUuid: string, name: string) => {
    changeOrganizationName({
      variables: {
        organizationUuid,
        nameDelta: name,
      }
    })
  }

  const archive = (organizationUuid: string) => {
    archiveOrganization({
      variables: {
        organizationUuid,
      }
    })
  }

  const approve = (organizationUuid: string) => {
    approveOrganization({
      variables: {
        organizationUuid,
      }
    })
  }

  const restore = (organizationUuid: string) => {
    restoreOrganization({
      variables: {
        organizationUuid,
      }
    })
  }

  const requestTopImpressions = (fromTimestamp: string, toTimestamp: string) => {
    mostImpressionsByOrganization({
      variables: {
        sampleSize: 10,
        fromTimestamp,
        toTimestamp,
      }
    })
  }

  const requestTopSearchPhrases = (fromTimestamp: string, toTimestamp: string) => {
    mostSearchedTerms({
      variables: {
        sampleSize: 12,
        fromTimestamp,
        toTimestamp,
      }
    })
  }

  const requestDeprecatedOrganizations = (page: number, pageSize: number, keywords: string) => {
    fetchDeprecatedOrganizations({
      variables: {
        searchParams: {
          page,
          pageSize,
          search: keywords,
          criteria: [],
        }
      }
    })
  }
  
  const requestUnapprovedOrganizations = (page: number, pageSize: number, keywords: string) => {
    fetchUnapprovedOrganizations({
      variables: {
        searchParams: {
          page,
          pageSize,
          search: keywords,
          criteria: [],
        }
      }
    })
  }

  return (
    <PortfolioContext.Provider value={{
      sessionContext, setSessionContext,
      organizations,
      organizationPagination: pagination,
      deprecatedOrganizations,
      deprecatedOrganizationPagination,
      unapprovedOrganizations,
      unapprovedOrganizationPagination, 
      impressions,
      impressionsByOrganization: topOrganizations,
      impressionsBySearchPhrase: topSearchPhrases,
      isLoadingOrganization: portfolioContext.loading,
      isLoadingUnapprovedOrganizations: unapprovedOrganizationContext.loading,
      isLoadingDeprecatedOrganizations: deprecatedOrganizationContext.loading,
      fetchContext,
      nameChangeContext,
      portfolioPage,
      portfolioSearch,
      changeName,
      archive,
      approve,
      restore,
      isArchivingOrganizaiton: archiveContext.loading,
      requestImpressions,
      requestTopImpressions,
      requestTopSearchPhrases,
      requestDeprecatedOrganizations,
      requestUnapprovedOrganizations,
      hookForCategory,
      hookForMedia,
      hookForGeo,
      blankOrganization,
    }}>
        {children}
    </PortfolioContext.Provider>
  )
}

const usePortfolio = (): any => {
  const context = useContext(PortfolioContext)
  if (context === undefined) {
    throw new Error('usePortfolio must be used within PortfolioProvider');
  }
  return context;
}

const useCategory = (): any => {
  const context = useContext(PortfolioContext);
  if (context === undefined) {
    throw new Error('useCategory must be used within PortfolioProvider');
  }
  return context.hookForCategory;
}

const useMedia = (): any => {
  const context = useContext(PortfolioContext);
  if (context === undefined) {
    throw new Error('useMedia must be used within PortfolioProvider');
  }
  return context.hookForMedia;
}

const useGeo = (): any => {
  const context = useContext(PortfolioContext);
  if (context === undefined) {
    throw new Error('useGeo must be used within PortfolioProvider');
  }
  return context.hookForGeo;
}

export { 
  PortfolioContext, 
  PortfolioProvider,
  usePortfolio,
  useCategory,
  useMedia,
  useGeo,
}