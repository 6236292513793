import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ServiceUrl, AuthorizeUrl } from 'data/domains';
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ networkError }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${ServiceUrl()}/graphql`
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
})

export function updateGraphqlHeaders() {
  const context: any = sessionStorage.getItem("context");
  const authLink = setContext((_, { headers }) => {
    return {
        headers: {
          ...headers,
          'Authorization': (context
            ? `JWT ${context}`
            : '')
        }
    }
  })

  client.setLink(
    errorLink.concat(authLink.concat(httpLink))
  );
}
updateGraphqlHeaders() // Init

export default client;