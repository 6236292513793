import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthorizeUrl } from 'data/domains';
import { usePortfolio } from 'context/PortfolioContext';
import ThreeDotLoader from 'views/components/loaders';

import 'assets/css/pagelayout.scss';

const CategoriesForEvents = lazy(() => import('views/categoriesForEvents'));
const CategoriesForOrganizations = lazy(() => import('views/categoriesForOrganizations'));
const Home = lazy(() => import('views/index'));
const Merchants = lazy(() => import('views/merchants'));
const UnapprovedMerchants = lazy(() => import('views/merchants_unapproved'));
const DeprecatedMerchants = lazy(() => import('views/merchants_deprecated'));
const CategoryComposer = lazy(() => import('views/categoryComposer'));
const LoginContext = lazy(() => import('context/ContextFromLoginCallback'));
const LogoffContext = lazy(() => import('context/ContextClearLogoutCallback'));

const App = () => {
  const { sessionContext } = usePortfolio();

  if (window.location.pathname.indexOf('/.scope') !== -1) {
    return (
      <Suspense fallback={<ThreeDotLoader show={true} />}>
        <Routes>
          <Route path="/.scope/:scope" element={<LoginContext/>}/>
        </Routes>
      </Suspense>
    );
  } else if (!sessionContext?.identityUuid) {
    window.location.href = AuthorizeUrl();
    return (<></>);
  }

  return (
    <div>
      <Suspense fallback={<ThreeDotLoader show={true} />}>
        <Routes>
          <Route path="/categories/organizations/:categoryAction" 
            element={<CategoryComposer categoryType='ORGANIZATION'/>}/>
          <Route path="/categories/organizations" element={<CategoriesForOrganizations/>}/>
          <Route path="/categories/events/:categoryAction" 
            element={<CategoryComposer categoryType='EVENT'/>}/>
          <Route path="/categories/events" element={<CategoriesForEvents/>}/>
          <Route path="/merchants" element={<Merchants/>}/>
          <Route path="/merchants/unapproved" element={<UnapprovedMerchants/>}/>
          <Route path="/merchants/deprecated" element={<DeprecatedMerchants/>}/>
          <Route path="/logout" element={<LogoffContext/>}/>
          <Route path="/" element={<Home />}/>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
