import React, { createContext } from 'react';
import { AlertNoticeAnchor } from 'context/AlertLayoutContext';
import PageLayoutHeader from 'context/PageLayoutContext/PageLayoutHeader';
import PageLayoutFooter from 'context/PageLayoutContext/PageLayoutFooter';
import ThreeDotLoader from 'views/components/loaders';

interface IPLContentProps {
  loading?: boolean | undefined,
  noheader?: boolean | undefined,
  children: any,
}

const PageLayoutContainer = (props: any) => {
  return (
    <div className='pagelayout-container'>
      {props.children}
    </div>
  )
}

const PageLayoutFixedSubNavBar = (props: any) => {
  return (
    <div className='secondary-navigation'>
      <div className={`container${props.pushRight ? ' push-right': ''}`}>
        {props.children}
      </div>
    </div>
  )
}

const PageLayoutContent = (props: IPLContentProps) => {
  const { children, noheader, loading } = props;
  return (
    <React.Fragment>
      <AlertNoticeAnchor />
      <div className={`pagelayout-${noheader ? 'noheader-' : ''}content`}>
        <ThreeDotLoader show={loading || false} />
        <div className='container'>
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}

const PageLayoutContext = createContext({});

const PageLayoutProvider = (props: any) => {
  
  return (
    <PageLayoutContext.Provider value={{}}>
      <PageLayoutHeader/>
        {props.children}
      <PageLayoutFooter />
    </PageLayoutContext.Provider>
  )
}

export { 
  PageLayoutContext, 
  PageLayoutProvider, 
  PageLayoutContainer, 
  PageLayoutFixedSubNavBar,
  PageLayoutContent,
};